import { defineStore } from 'pinia';

export const useTaskStore = defineStore('task', {
  state: () => ({
    countTaskUnfulfilled: 0,
  }),
  getters: {},
  actions: {
    setCountTaskUnfulfilled(count: number) {
      this.countTaskUnfulfilled = count;
    },
    resetCountTask() {
      this.countTaskUnfulfilled = 0;
    }
  },
})
