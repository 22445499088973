
  import { defineComponent, computed, PropType } from 'vue';

  enum Type {sm = 'sm', md = 'md', full = 'full'}
  export default defineComponent({
    name: 'ConfirmModal',
    props: {
      title: {
        type: String,
        default: ''
      },
      modelValue: {
        type: Boolean,
        default: false,
      },
      content: {
        type: String,
        default: ''
      },
      contentClass: {
        type: String,
        default: '',
      },
      yesBtnTxt: {
        type: String,
        default: 'Xác nhận'
      },
      noBtnTxt: {
        type: String,
        default: 'Hủy bỏ'
      },
      size: {
        type: String as PropType<Type>,
        default: 'sm'
      },
      yesBtnColor: {
        type: String,
        default: 'primary'
      },
      noBtnColor: {
        type: String,
        default: 'primary'
      },
      noBackdropDismiss: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
    },
    emits: ['update:modelValue', 'yesClick', 'noClick'],
    setup(props, { emit }) {
      const width = computed(() => {
        if (props.size === 'full')
          return '100%';
        else if (props.size === 'md')
          return '700px';
        else
          return '500px'
      });
      const open = computed({
        get: () => props.modelValue,
        set: value => emit('update:modelValue', value)
      });

      function noClick() {
        open.value = false;
        emit('noClick');
      }
      
      function hide(event: Event) {
        if (event) {
          noClick();
        }
      }

      return { open, width, noClick, hide }
    },
  });
