<template>
    <span class="icon" :style="{width: size, color}" :class="{'opacity-50 cursor-not-allowed': disabled, [extraClass]: !!extraClass}">
        <span class="svg" v-html="icon"></span>
        <q-tooltip v-if="!displayTitle && !!title" class="bg-black" anchor="top middle" self="bottom middle">{{title}}</q-tooltip>
        <span v-if="displayTitle"
              style="font-size: x-small;
              margin-left: -3px;
              color:black;
              white-space: nowrap;"
              class="icon-label"
        >{{title}}</span>
      </span>
</template>

<script>
  import themeIcons from 'src/helpers/icons';
  import { defineComponent, ref } from 'vue';

  export default defineComponent({
    name: 'AppIcons',
    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: 'fit-content'
      },
      color: {
        type: String,
        default: 'inherit'
      },
      class: {
        type: String,
        default: ''
      },
      title: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false
      },
      displayTitle: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const icon = ref(themeIcons[props.name]);
      const extraClass = ref(props.class);
      return { icon, icons: themeIcons, extraClass }
    }
  });
</script>
