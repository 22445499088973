import { defineStore } from 'pinia';

export const useNotRedNotificationStore = defineStore('notRedNotification', {
  state: () => ({
    hasFetchData: false,
    notReadNotification: {
      task: 0,
      approve: 0,
      'processing-transaction': 0,
      visa: 0,
    } as {[n: string]: number},
  }),
  getters: {
  },
  actions: {
    setHasFetchData(hasFetchData = true) {
      this.hasFetchData = hasFetchData;
    },
    setNotReadNotification(data: {[n: string]: number}) {
      this.notReadNotification = {task: 0, approve: 0, 'processing-transaction': 0, visa: 0, ...data};
    },
    resetNotReadNotification() {
      this.hasFetchData = false;
      this.notReadNotification = {task: 0, approve: 0, 'processing-transaction': 0, visa: 0};
    }
  },
});
