import { RouteRecordRaw } from 'vue-router';
import {permissions} from 'src/helpers/employee';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {path: '', name: 'Index', meta: {title: 'Trang chủ'}, component: () => import('pages/Index.vue')},
      {path: '/dashboard/consultant', name: 'ConsultantDashboard', meta: {title: 'Tổng quan tư vấn', permissions: {OR: [permissions.DASHBOARD_CONSULTANT_PERSON, permissions.DASHBOARD_CONSULTANT_TEAM, permissions.DASHBOARD_CONSULTANT_DEPARTMENT, permissions.DASHBOARD_CONSULTANT_DIRECTOR]}}, component: () => import('components/dashboard/Consultant.vue')},
      {path: '/dashboard/customer-management', name: 'CustomerManagementDashboard', meta: {title: 'Tổng quan quản lý khách hàng', permissions: {OR: [permissions.DASHBOARD_CUSTOMER_PERSON, permissions.DASHBOARD_CUSTOMER_TEAM, permissions.DASHBOARD_CUSTOMER_DEPARTMENT, permissions.DASHBOARD_CUSTOMER_DIRECTOR]}}, component: () => import('components/dashboard/CustomerManagement.vue')},
      {path: '/dashboard/finance', name: 'FinanceDashboard', meta: {title: 'Tổng quan tài chính', permissions: {OR: [permissions.DASHBOARD_FINANCE_PERSON, permissions.DASHBOARD_FINANCE_TEAM, permissions.DASHBOARD_FINANCE_DEPARTMENT, permissions.DASHBOARD_FINANCE_DIRECTOR]}}, component: () => import('components/dashboard/Finance.vue')},
      {path: '/dashboard/tasks', name: 'TaskDashboard', meta: {title: 'Dashboard công việc', permissions: permissions.VIEW_TASKS_DASHBOARD}, component: () => import('components/dashboard/EmployeeTaskManagement.vue')},
      // Department
      {path: '/departments', name: 'DepartmentsList', meta: {title: 'Danh sách phòng ban', permissions: {OR: [permissions.DEPARTMENT_CREATE, permissions.DEPARTMENT_EDIT, permissions.DEPARTMENT_DELETE]}}, component: () => import('pages/departments/DepartmentsIndex.vue')},
      {path: '/departments/new', name: 'DepartmentsNew', meta: {title: 'Thêm mới phòng ban', permissions: permissions.DEPARTMENT_CREATE}, component: () => import('pages/departments/DepartmentsCreate.vue')},
      {path: '/departments/:id', name: 'DepartmentsEdit', meta: {title: 'Sửa phòng ban', permissions: permissions.DEPARTMENT_EDIT}, component: () => import('pages/departments/DepartmentsEdit.vue')},

      // Late Interest Management
      {path: '/late-interest/:type', name: 'LateInterestType', meta: {permissions: {OR: [permissions.LATE_INTEREST_MANAGER, permissions.LATE_INTEREST_ALL_CUSTOMER, permissions.LATE_INTEREST_MANAGE_CUSTOMER]}}, component: () => import('pages/late_interest/Layout.vue'), children: [
          {path: 'managers', name: 'LateInterestTypeManager', meta: {permissions: permissions.LATE_INTEREST_MANAGER}, component: () => import('pages/late_interest/Managers.vue')},
          {path: 'customers', name: 'LateInterestTypeCustomer', meta: {permissions: {OR: [permissions.LATE_INTEREST_ALL_CUSTOMER, permissions.LATE_INTEREST_MANAGE_CUSTOMER]}}, component: () => import('pages/late_interest/Customers.vue')}
        ]},

      // Employee
      {path: '/employees', name: 'EmployeeIndex', meta: {title: 'Danh sách nhân viên', permissions: permissions.EMPLOYEE_VIEW}, component: () => import('pages/employees/EmployeeIndex.vue')},
      {path: '/employees/new', name: 'EmployeeCreate', meta: {title: 'Thêm mới nhân viên', permissions: permissions.EMPLOYEE_CREATE}, component: () => import('pages/employees/EmployeeCreate.vue')},
      {path: '/employees/edit/:id', name: 'EmployeeEdit', meta: {title: 'Sửa thông tin nhân viên', permissions: permissions.EMPLOYEE_EDIT}, component: () => import('pages/employees/EmployeeEdit.vue')},
      {path: '/employees/permission/:id?', name: 'EmployeePermission', meta: {title: 'Phân quyền nhân viên', permissions: permissions.EMPLOYEE_SET_PERMISSION}, component: () => import('pages/employees/EmployeePermission.vue')},

      // Deleted Customer
      {path: '/deleted-customers', name: 'DeletedCustomerIndex', meta: {title: 'Danh sách khách hàng đã xóa', permissions: permissions.VIEW_DELETED_CUSTOMERS}, component: () => import('pages/deleted_customer/DeletedCustomerIndex.vue')},

      // Customer
      {path: '/customers/hand-over-customers', name: 'CustomerHandoverUpdate', meta: {title: 'Bàn giao khách hàng', permissions: permissions.EMPLOYEE_HAND_OVER_CUSTOMERS}, component: () => import('pages/customer_handover/CustomerHandoverUpdate.vue')},
      {path: '/customers', name: 'CustomersIndex', meta: {title: 'Danh sách khách hàng', permissions: {OR: [permissions.CUSTOMER_ALL_VIEW, permissions.CUSTOMER_MANAGED_VIEW]}}, component: () => import('pages/customers/CustomerIndex.vue')},
      {path: '/customers/new', name: 'CustomersCreate', meta: {title: 'Thêm mới khách hàng', permissions: permissions.CUSTOMER_CREATE}, component: () => import('pages/customers/CustomerCreate.vue')},
      {
        path: '/customers/:id',
        name: 'CustomersEdit',
        meta: {
          title: 'Sửa thông tin khách hàng',
          permissions: {
            AND: [permissions.CUSTOMER_EDIT],
            OR: [permissions.CUSTOMER_SHOW_LOGIN, permissions.CUSTOMER_SHOW_GENERAL, permissions.CUSTOMER_SHOW_CONTACT, permissions.CUSTOMER_SHOW_FB, permissions.CUSTOMER_SHOW_IMAGES, permissions.CUSTOMER_SHOW_MANAGER, permissions.CUSTOMER_LOAN_LIMIT_SHOW]
          }
        },
        component: () => import('pages/customers/CustomerEdit.vue')
      },
      {path: '/customers/:id', component: () => import('pages/customers/CustomerShowLayout.vue'),
        children: [
          {
            path: 'show',
            name: 'CustomersInfo',
            meta: {
              title: 'Thông tin khách hàng',
              permissions: {
                OR: [permissions.CUSTOMER_SHOW_LOGIN, permissions.CUSTOMER_SHOW_GENERAL, permissions.CUSTOMER_SHOW_CONTACT, permissions.CUSTOMER_SHOW_FB, permissions.CUSTOMER_SHOW_IMAGES, permissions.CUSTOMER_SHOW_MANAGER]
              }
            },
            component: () => import('pages/customers/CustomerShow.vue')},
          {
            path: 'task',
            name: 'CustomerWork',
            meta: {
              title: 'Công việc của khách hàng',
              permissions: {
                OR: [permissions.LOAN_SHOW, permissions.CUSTOMER_SHOW_LOGIN, permissions.CUSTOMER_SHOW_GENERAL, permissions.CUSTOMER_SHOW_CONTACT, permissions.CUSTOMER_SHOW_FB, permissions.CUSTOMER_SHOW_IMAGES, permissions.CUSTOMER_SHOW_MANAGER]
              }
            },
            component: () => import('pages/tasks/CustomerWork.vue')
          },
          {path: 'loan', name: 'CustomersLoan', component: () => import('pages/loans/CustomerLoan.vue'),
            children: [
              {path: '', name: 'CustomersLoanShow', meta: {title: 'Đóng lãi hợp đồng', permissions: {OR: [permissions.LOAN_SHOW, permissions.LOAN_PAY_INTEREST]}}, component: () => import('components/loan/TabPayInterest.vue')},
              {path: 'pay-part-principal', name: 'LoanPayPartPrincipal',meta: {title: 'Trả bớt gốc', permissions: permissions.LOAN_SUB_PRINCIPAL}, component: () => import('components/loan/TabPayPartPrincipal.vue')},
              {path: 'loan-more', name: 'LoanMore', meta: {title: 'Vay thêm', permissions: permissions.LOAN_ADD_PRINCIPAL}, component: () => import('components/loan/TabLoanMore.vue')},
              {path: 'close-contract', name: 'LoanCloseContract', meta: {title: 'Đóng hợp đồng', permissions: permissions.LOAN_CLOSE}, component: () => import('components/loan/TabCloseContract.vue')},
              {path: 'credit-debt', name: 'LoanCreditDebt', meta: {title: 'Dư nợ', permissions: permissions.LOAN_CREDIT_DEBT}, component: () => import('components/loan/TabCreditDebt.vue')},
              {path: 'history', name: 'LoanHistory', meta: {title: 'Lịch sử thao tác', permissions: permissions.LOAN_SHOW}, component: () => import('components/loan/TabHistory.vue')},
              {path: 'history-notes', name: 'LoanHistoryNotes', meta: {title: 'Lịch sử giao dịch giấy tờ', permissions: permissions.LOAN_HISTORY_NOTE}, component: () => import('components/loan/TabHistoryNotes.vue')},
              {path: 'times/:loan_id', name: 'TabLoanHistory', meta: {title: 'Lịch sử vay lãi', permissions: permissions.LOAN_TIMES_VIEW}, component: () => import('components/loan/TabLoanHistory.vue')},
            ]
          },
        ]
      },
      {path: '/tasks', name: 'TasksIndex', meta: {title: 'Danh sách công việc'}, component: () => import('pages/tasks/TasksIndex.vue')},
      {path: '/tasks/new', name: 'TasksCreate', meta: {title: 'Thêm mới công việc', permissions: permissions.TASK_CREATE}, component: () => import('pages/tasks/TasksCreate.vue')},
      {path: '/tasks/edit/:id', name: 'TasksEdit', meta: {title: 'Sửa công việc', permissions: permissions.TASK_EDIT}, component: () => import('pages/tasks/TasksEdit.vue')},
      {path: '/tasks/:id', name: 'TasksView', meta: {title: 'Chi tiết công việc'}, component: () => import('pages/tasks/TasksView.vue')},

      {path: '/transaction-report', name: 'TransactionReportIndex', meta: {title: 'Báo cáo giao dịch', permissions: permissions.REPORT_VIEW}, component: () => import('pages/transaction_reports/Index.vue')},

      // Transaction
      {path: '/money-check', name: 'MoneyCheckIndex', meta: {title: 'Danh sách kiểm tra tiền', permissions: {OR: [permissions.TRANSACTION_VIEW, permissions.TRANSACTION_MANAGED_VIEW]}}, component: () => import('pages/transactions/MoneyCheck.vue')},
      {path: '/disbursement', name: 'DisbursementIndex', meta: {title: 'Danh sách yêu cầu giải ngân', permissions: {OR: [permissions.TRANSACTION_VIEW, permissions.TRANSACTION_MANAGED_VIEW]}}, component: () => import('pages/transactions/Disbursement.vue')},

      // Loan
      {path: '/loans', name: 'LoansIndex', meta: {title: 'Danh sách khoản vay', permissions: {OR: [permissions.LOAN_ALL_VIEW, permissions.LOAN_MANAGED_VIEW]}}, component: () => import('pages/loans/LoansIndex.vue')},

      // Chat
      {path: '/chats/:id?',  name: 'ChatsIndex', meta: {title: 'Chat', permissions: {OR: [permissions.CHAT_SHOW_ALL, permissions.CHAT_SHOW_MANAGED, permissions.CHAT]}}, component: () => import('pages/chats/ChatIndex.vue')},
      {path: '/chat-list', name: 'ChatsList', meta: {title: 'Chat', permissions: {OR: [permissions.CHAT_SHOW_ALL, permissions.CHAT_SHOW_MANAGED, permissions.CHAT]}}, component: () => import('pages/chats/ChatList.vue')},
      {path: '/chat-box/:id', name: 'ChatsBox', meta: {title: 'Chat', permissions: {OR: [permissions.CHAT_SHOW_ALL, permissions.CHAT_SHOW_MANAGED, permissions.CHAT]}}, component: () => import('pages/chats/ChatBox.vue')},

        // Currency
      {path: '/currencies', name: 'CurrencyIndex', meta: {title: 'Thiết lập tỷ giá ngoại tệ', permissions: permissions.SET_RATE}, component: () => import('pages/currencies/CurrencyIndex.vue')},

      // Punish
      {path: '/punishes', name: 'PunishesIndex', meta: {title: 'Thiết lập tính toán phạt', permissions: permissions.SET_PENALTY}, component: () => import('pages/punishes/PunishesIndex.vue')},

      // Bank Account
      {path: '/bank-accounts', name: 'BankAccountsIndex', meta: {title: 'Thiết lập tài khoản ngân hàng', permissions: permissions.SET_BANK_ACCOUNT}, component: () => import('pages/bank_accounts/BankAccountsIndex.vue')},

      // Banner
      {path: '/banners', name: 'BannersIndex', meta: {title: 'Thiết lập Banner', permissions: permissions.SET_BANNER}, component: () => import('pages/banners/BannersIndex.vue')},
      {path: '/banners/new', name: 'BannersCreate', meta: {title: 'Thêm mới banner', permissions: permissions.SET_BANNER}, component: () => import('pages/banners/BannersCreate.vue')},
      {path: '/banners/:id', name: 'BannersEdit', meta: {title: 'Sửa thông tin banner', permissions: permissions.SET_BANNER}, component: () => import('pages/banners/BannersEdit.vue')},

      // Fund
      {path: '/funds', name: 'FundsIndex', meta: {title: 'Quản lý quỹ', permissions: permissions.FUND_VIEW}, component: () => import('pages/funds/FundsIndex.vue')},

      // Profile
      {path: '/profile', name: 'Profile', meta: {title: 'Thông tin cá nhân'}, component: () => import('pages/Profile.vue')},
      {path: '/change-password', name: 'ChangePassword', meta: {title: 'Đổi mật khẩu'}, component: () => import('pages/ChangePassword.vue')},

      // Notifications
      {path: '/notifications', name: 'NotificationsIndex', meta: {title: 'Danh sách thông báo'}, component: () => import('pages/notifications/NotificationsIndex.vue')},

      // Notification Setting
      {path: 'notification-schedules', name: 'NotificationScheduleIndex', meta: {title: 'Thiết lập thông báo', permissions: permissions.SET_NOTIFICATION_SCHEDULE}, component: () => import('src/pages/notification_schedules/NotificationScheduleIndex.vue')},
      {path: 'notification-schedule/new', name: 'NotificationScheduleCreate', meta: {title: 'Tạo thông báo', permissions: permissions.SET_NOTIFICATION_SCHEDULE}, component: () => import('pages/notification_schedules/NotificationScheduleCreate.vue')},
      {path: 'notification-schedule/:id', name: 'NotificationScheduleEdit', meta: {title: 'Sửa thông báo', permissions: permissions.SET_NOTIFICATION_SCHEDULE}, component: () => import('pages/notification_schedules/NotificationScheduleEdit.vue')},
      {path: 'notification-schedule/show/:id', name: 'NotificationScheduleShow', meta: {title: 'Chi tiết thông báo', permissions: permissions.SET_NOTIFICATION_SCHEDULE}, component: () => import('pages/notification_schedules/NotificationScheduleShow.vue')},
    ],
    meta: { auth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('pages/Login.vue'),
    meta: {meta: {title: 'Đăng nhập'}, guess: true},
  },
  {
    path: '/reset-password/:token',
    component: () => import('pages/ResetPassword.vue'),
    meta: {title: 'Đặt lại mật khẩu'}
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('src/pages/ForgetPassword.vue'),
    meta: {title: 'Quên mật khẩu', guess: true},
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('pages/Maintenance.vue'),
    meta: {title: 'Bảo trì'}
  },
  {
    path: '/system/up/:token',
    name: 'MaintenanceDone',
    component: () => import('pages/MaintenanceDone.vue'),
    meta: {title: 'Bảo trì xong'}
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
  {path: '/customer/reset-password/:token', name: 'CustomerResetPassword', meta: {title: 'Cấp lại mật khẩu'}, component: () => import('pages/customers/CustomerResetPassword.vue')},
];

export default routes;
