import {defineStore} from 'pinia';

export const useCountWaitingTransactionStore = defineStore('countWaitingTransactionStore', {
  state: () => ({
    hasUpdate: false,
    waitingDisbursements: 0,
    waitingMoneyCheck: 0
  }),
  getters: {
    //
  },
  actions: {
    setWaitingDisbursementTransactionsNumber(count: number) {
      this.waitingDisbursements = count;
    },
    setWaitingMoneyCheckTransactionsNumber(count: number) {
      this.waitingMoneyCheck = count;
    },
    setHasUpdate(hasUpdate = true) {
      this.hasUpdate = hasUpdate;
    },
    resetTransactionNumber() {
      this.waitingDisbursements = 0;
      this.waitingMoneyCheck = 0;
    }
  }
});
