/* eslint-disable */
import {useAuthStore} from 'src/stores/auth/useAuthStore';
import {storeToRefs} from 'pinia';
import {arrayContainArray} from 'src/services/utils/array';
import {_RouteRecordBase} from 'vue-router';
import Routes from 'src/router/routes';

export const removeEmptyFromObject = (obj: {[n: string]: any}) => {
    Object.entries(obj).forEach(([key, val])  => {
      if ( val === null
        || val === ''
        || val === undefined
        || (Array.isArray(val) && !val.length)
        || (typeof val === 'object' && !Object.keys(removeEmptyFromObject(val)).length)
      ) {
        delete obj[key];
      }
    });
    return obj;
};

export const checkValidUrl = (urlString: string) => {
  let url;
  try {
    url =new URL(urlString);
  }
  catch(e){
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
};

export const encodeURIObject = (obj: object) => {
  let convertedObj: {[n: string]: string} = {};
  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      convertedObj[key] = encodeURIComponent(value.join('#'))
    }
    else if (!!value) {
      convertedObj[key] = encodeURIComponent(value);
    }
  }
  return convertedObj;
};

export const formatNumber = (val: number|string, currency: string = '') => {
    if (!val) return val + ' ' + currency;
    // remove sign if negative
    let sign = 1;
    if (typeof val === 'string') val = parseInt(val);
    if (val < 0) {
        sign = -1;
        val = -val;
    }

    // trim the number decimal point if it exists
    let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();

    while (/(\d+)(\d{3})/.test(num.toString())) {
        // insert comma to 4th last position to the match number
        num = num.toString().replace(/(\d+)(\d{3})/, '$1' + '.' + '$2');
    }

    // add number after decimal point
    if (val.toString().includes('.')) {
        num = num + ',' + val.toString().split('.')[1];
    }

    // return result with - sign if negative
    return (sign < 0 ? '-' + num : num) + ' ' + currency;
};

export const getAvatarName = (str: string) => {
  if(!str) return '';

  let names = str.split(' ')

  if(names.length == 1)
    return names[0].charAt(0).toUpperCase();
  else
    return (names[0].charAt(0) + names[names.length -1].charAt(0).toUpperCase());
}

export const scrollToBottom = (element: HTMLElement|null) => {
  if (!element) return;
  element.scrollTop = element.scrollHeight || 0;
}

export const scrollToMidElement = (element: HTMLElement|null) => {
  if (!element) return;
  element.scrollTop = element.scrollHeight / 4 || 0;
}

export const exportFile = (nameFile: string, res: any) => {
  const fileURL = window.URL.createObjectURL(new Blob([res]));
  const el = document.createElement('a');
  el.href = fileURL;
  el.setAttribute('download', nameFile);
  document.body.appendChild(el);
  el.click();
  el.remove();
}

export const canOne = (permission: string, ignoreAdmin: boolean = false) => {
  const authStore = useAuthStore();
  const {isSuperAdmin, permissions} = storeToRefs(authStore);

  if (permissions.value.includes(permission)) {
      return true;
  }

  if (isSuperAdmin.value) {
      return !ignoreAdmin;
  }
  else {
      return false;
  }
}

export const canAll = (args: string[]|string, ignoreAdmin: boolean = false) => {
  const authStore = useAuthStore();
  const {isSuperAdmin, permissions} = storeToRefs(authStore);

    if (arrayContainArray(permissions.value, typeof args === 'string' ? [args] : args)) {
        return true;
    }

    if (isSuperAdmin.value) {
        return !ignoreAdmin;
    }
    else {
        return false;
    }
}

export const canOr = (args: string[]|string, ignoreAdmin: boolean = false) => {
  const authStore = useAuthStore();
  const {isSuperAdmin, permissions} = storeToRefs(authStore);
  args = typeof args === 'string' ? [args] : args;

    if (args.filter(item => permissions.value.includes(item)).length) {
        return true;
    }

    if (isSuperAdmin.value) {
        return !ignoreAdmin;
    } else {
        return false;
    }
}

export const can = (permissions: string|string[]|{[n: string]: string|string[]}, ignoreAdmin: boolean = false) => {
  if (typeof permissions === 'string') {
      return canOne(permissions, ignoreAdmin);
  }

  else if (Array.isArray(permissions))
    return canAll(permissions, ignoreAdmin);

  else {
    const orConditions = Object.keys(permissions).includes('OR') ? canOr(permissions.OR, ignoreAdmin) : true;
    const andConditions = Object.keys(permissions).includes('AND') ? canAll(permissions.AND, ignoreAdmin) : true;

    return orConditions && andConditions;
  }
}

export function canTo (routeName: string) {
  const route = getRoute(routeName, Routes),
    routePermissions = !!route.meta ? route.meta.permissions as string|string[]|{[n: string]: string|string[]} : null;

  return !routePermissions || can(routePermissions);
}

export function canToAny (routes: string[]) {
  let can = false;
  for (let i = 0; i < routes.length; i++) {
    if (canTo(routes[i])) {
      can = true;
      break;
    }
  }

  return can;
}

export function getRoute (routeName: string, routes: _RouteRecordBase[]) {
    let route: _RouteRecordBase = {path: ''};
    for (let i = 0; i < routes.length; i++) {
      let routeCheck = routes[i];
      if (routeCheck.name === routeName) {
        route = routeCheck;
        break;
      }
      if (!!routeCheck.children) {
        let matchedRoute = getRoute(routeName, routeCheck.children);
        if (!!matchedRoute.name) {
          route = matchedRoute;
          break;
        }
      }
    }

    return route;
}
