import script from "./AppDataTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppDataTable.vue?vue&type=script&setup=true&lang=ts"

import "./AppDataTable.vue?vue&type=style&index=0&id=7406c518&lang=sass"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QBtn,QPagination,QCard,QItemLabel});
