export const status = [
  {
    label: 'Đang làm việc',
    id: 1
  },
  {
    label: 'Đã nghỉ việc',
    id: 0
  }
];

export const permissions = {
  TASK_CREATE: 'create-tasks',
  TASK_EDIT: 'edit-tasks',
  TASK_DELETE: 'delete-tasks',

  CUSTOMER_ALL_VIEW: 'view-all-customers',
  CUSTOMER_MANAGED_VIEW: 'view-managed-customers',
  CUSTOMER_CREATE: 'create-customer',
  CUSTOMER_SHOW_LOGIN: 'show-login-customer',
  CUSTOMER_SHOW_GENERAL: 'show-general-customer',
  CUSTOMER_SHOW_CONTACT: 'show-contact-customer',
  CUSTOMER_SHOW_FB: 'show-fb-customer',
  CUSTOMER_SHOW_IMAGES: 'show-images-customer',
  CUSTOMER_SHOW_MANAGER: 'show-manager-customer',
  CUSTOMER_EDIT: 'edit-customer',
  CUSTOMER_EMAIL_EDIT: 'edit-email-customer',
  CUSTOMER_EMAIL_CREATE: 'create-email-customer',
  CUSTOMER_EMAIL_SHOW: 'show-email-customer',
  CUSTOMER_LOAN_LIMIT_SHOW: 'show_loan_limit_customer',
  CUSTOMER_LOAN_LIMIT_EDIT: 'edit_loan_limit_customer',
  CUSTOMER_LOAN_LIMIT_CREATE: 'create_loan_limit_customer',
  CUSTOMER_DELETE: 'delete-customer',
  VIEW_DELETED_CUSTOMERS: 'view-deleted-customers',

  EMPLOYEE_VIEW: 'view-employees',
  EMPLOYEE_CREATE: 'create-employee',
  EMPLOYEE_EDIT: 'edit-employee',
  EMPLOYEE_DELETE: 'delete-employee',
  DEPARTMENT_CREATE: 'create-department',
  DEPARTMENT_EDIT: 'edit-department',
  DEPARTMENT_DELETE: 'delete-department',
  EMPLOYEE_SET_PERMISSION: 'set-permission',
  EMPLOYEE_HAND_OVER_CUSTOMERS: 'hand-over-customers',

  LOAN_ALL_VIEW: 'view-all-loans',
  LOAN_MANAGED_VIEW: 'view-managed-loans',
  LOAN_CREATE: 'create-loan',
  LOAN_SHOW: 'show-loan',
  LOAN_PAY_INTEREST: 'pay-interest',
  LOAN_SUB_PRINCIPAL: 'pay-part-principal',
  LOAN_ADD_PRINCIPAL: 'loan-more',
  LOAN_CLOSE: 'close-loan',
  LOAN_CREDIT_DEBT: 'update-credit-debt',
  LOAN_HISTORY_NOTE: 'manage-history-note',
  LOAN_LOCK: 'lock-loan',
  LOAN_REOPEN: 'open-loan',
  LOAN_FREEZE: 'freeze-loan',
  LOAN_UNFREEZE: 'unfreeze-loan',
  LOAN_TIMES_VIEW: 'view-loan-times',

  CHAT_SHOW_ALL: 'show-all-chat',
  CHAT_SHOW_MANAGED: 'show-managed-chat',
  CHAT: 'chat',

  TRANSACTION_VIEW: 'view-transactions',
  TRANSACTION_MANAGED_VIEW: 'view-managed-transactions',
  TRANSACTION_RECEIVE: 'receive-transaction',
  TRANSACTION_MANAGED_DELETE: 'delete-managed-transaction',

  REPORT_VIEW: 'view-transaction-report',

  FUND_VIEW: 'view-funds',
  FUND_CREATE: 'create-fund',
  FUND_EDIT: 'edit-fund',
  FUND_DELETE: 'delete-fund',

  SET_RATE: 'set-rate',
  SET_PENALTY: 'set-penalty',
  SET_BANK_ACCOUNT: 'set-bank-account',
  SET_BANNER: 'set-banner',
  SET_NOTIFICATION_SCHEDULE: 'set-notification-schedule',

  DASHBOARD_CONSULTANT_PERSON: 'dashboard-consultant-person',
  DASHBOARD_CONSULTANT_TEAM: 'dashboard-consultant-team',
  DASHBOARD_CONSULTANT_DEPARTMENT: 'dashboard-consultant-department',
  DASHBOARD_CONSULTANT_DIRECTOR: 'dashboard-consultant-director',
  DASHBOARD_CUSTOMER_PERSON: 'dashboard-customer-person',
  DASHBOARD_CUSTOMER_TEAM: 'dashboard-customer-team',
  DASHBOARD_CUSTOMER_DEPARTMENT: 'dashboard-customer-department',
  DASHBOARD_CUSTOMER_DIRECTOR: 'dashboard-customer-director',
  DASHBOARD_FINANCE_PERSON: 'dashboard-finance-person',
  DASHBOARD_FINANCE_TEAM: 'dashboard-finance-team',
  DASHBOARD_FINANCE_DEPARTMENT: 'dashboard-finance-department',
  DASHBOARD_FINANCE_DIRECTOR: 'dashboard-finance-director',
  VIEW_TASKS_DASHBOARD: 'view-tasks-dashboard',

  BELL_IT_CREATE_CUSTOMER_PASSWORD : 'bell-it-create-customer-password',
  BELL_IT_FREEZE_LOAN: 'bell-it-freeze-loan',

  BELL_FINANCE_BANK_ACCOUNT_IS_OVER_LIMIT: 'bell-finance-bank-account-is-over-limit',

  BELL_CUSTOMER_OVER_DAY_NO_REPLY: 'bell-customer-over-day-no-reply',
  BELL_TASK_FOR_CONSULTANT: 'bell-task-for-consultant',
  BELL_CUSTOMER_OVER_DAY_NO_MANAGER: 'bell-customer-over-day-no-manager',
  BELL_CUSTOMER_OVER_DAY_NO_LOAN: 'bell-customer-over-day-no-loan',

  BELL_QLKH_KTKH: 'bell-qlkh-ktkh',
  BELL_QLKH_TC: 'bell-qlkh-tc',
  BELL_QLKH_QLKH: 'bell-qlkh-qlkh',
  BELL_QLKH_IT: 'bell-qlkh-it',

  LATE_INTEREST_MANAGER: 'late-interest-manager',
  LATE_INTEREST_ALL_CUSTOMER: 'late-interest-all-customer',
  LATE_INTEREST_MANAGE_CUSTOMER: 'late-interest-manage-customer',
}
