import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-483fafac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-h6 text-center" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    onEscapeKey: _ctx.noClick,
    "no-backdrop-dismiss": _ctx.noBackdropDismiss,
    onHide: _ctx.hide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        style: _normalizeStyle({width: _ctx.width, 'max-width': '80vw'})
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "heading", {}, () => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1)
              ], true)
            ]),
            _: 3
          }, 512), [
            [_vShow, _ctx.title]
          ]),
          _createVNode(_component_q_card_section, { class: "q-pt-none text-center" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "content", {}, () => [
                _createElementVNode("div", {
                  innerHTML: _ctx.content,
                  class: _normalizeClass(_ctx.contentClass)
                }, null, 10, _hoisted_2)
              ], true)
            ]),
            _: 3
          }),
          _createVNode(_component_q_card_actions, {
            align: "center",
            class: "pb-4"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "actions", {}, () => [
                _createVNode(_component_q_btn, {
                  outline: "",
                  label: _ctx.noBtnTxt,
                  color: _ctx.noBtnColor,
                  onClick: _ctx.noClick
                }, null, 8, ["label", "color", "onClick"]),
                _createVNode(_component_q_btn, {
                  class: "q-ml-xl",
                  label: _ctx.yesBtnTxt,
                  color: _ctx.yesBtnColor,
                  loading: _ctx.loading,
                  disable: _ctx.loading,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('yesClick')))
                }, null, 8, ["label", "color", "loading", "disable"])
              ], true)
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["style"])
    ]),
    _: 3
  }, 8, ["modelValue", "onEscapeKey", "no-backdrop-dismiss", "onHide"]))
}