import { defineStore } from 'pinia';
import {User} from 'src/models/auth';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    userProfile: {} as User,
  }),
  getters: {
    isSuperAdmin(state): boolean {
      return state.userProfile.is_super_admin;
    },
    permissions(state) {
      return state.userProfile.permissions || [];
    }
  },
  actions: {
    setIsAuthenticated(isAuthenticated = true) {
      this.isAuthenticated = isAuthenticated;
    },
    setUserProfile(data: User) {
      this.userProfile = data;
    },
    updateUserProfile(data: object) {
      this.userProfile = {...this.userProfile, ...data};
    },
    resetUserProfile() {
      this.userProfile = {} as User;
    }
  },
});
