import {apiRequest} from 'src/services/infs/apiRequest';
import { Employee } from 'src/models/employee';
import {EmployeeFetchResponse} from 'src/models/api';

export const getListEmployee = function () {
  return apiRequest('api/employees/list', 'get');
};

export const createEmployee = function (employee: Employee): Promise<EmployeeFetchResponse> {
  return apiRequest('api/employees', 'post', employee);
};

export const updateEmployee = function (id: number, employee: Employee): Promise<EmployeeFetchResponse> {
  return apiRequest(`api/employees/${id}`, 'put', employee);
};

export const getEmployeeById = function (id: number) {
  return apiRequest(`api/employees/${id}`, 'get');
};

export const destroy = function (id: number) {
  return  apiRequest(`api/employees/${id}`, 'delete');
};

export const requestCreateAccCustomer = function (customerId: number) {
  return apiRequest(`api/it-request/create-customer-password/${customerId}`, 'post')
}
