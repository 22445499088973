import moment from 'moment';

export const unixTimeToFormatDate = (timestamp: number, format = 'DD-MM-YYYY') => {
  return moment.unix(timestamp).format(format);
};

export const getCurrentDate = (format = 'DD-MM-YYYY') => {
  return moment().format(format);
};

export const isToday = (date: number) => {
  return unixTimeToFormatDate(date, 'DD-MM-YYYY') === getCurrentDate('DD-MM-YYYY');
}

export const getDiffDates = (to: string, from = '') => {
    const start = !!from ? moment(from.split('-').reverse().join('-')) : moment(moment().format('YYYY-MM-DD'));
    const end = moment(to.split('-').reverse().join('-'));
    const duration = moment.duration(end.diff(start));
    return duration.asDays() + 1;
};

export const addDaysToDate = (date: string, addedDays = 0) => {
    const momentDate = moment(date.split('-').reverse().join('-'));
    return momentDate.add(addedDays, 'days').format('DD-MM-YYYY');
};

export const getDiffTime = (from: number, to: number) => {
  const diffTime = to - from;
  const minutes = String(Math.floor(diffTime / 60))

  return minutes + ' phút';
}

export const diffDays = (from: string, to: string, format: string) => {
  const start = moment(from, format);
  const end = moment(to, format);

  return moment.duration(end.diff(start)).asDays();
}

export const formatDate = (date: string, format = 'DD/MM/YYYY') => {
  return moment(date).format(format);
};
