import {apiRequest} from 'src/services/infs/apiRequest';
import {ApiResponseList, ChangePasswordFetchResponse, EmployeeFetchResponse, UserProfileReponse} from 'src/models/api';
import {FilterData, LoanFilterData, TaskFilterData} from 'src/models/common';

export default {
  login(formData: object) {
    const secretKey = {
      grant_type: process.env.GRANT_TYPE,
      client_id: process.env.CLIENT_ID,
      scope: '*',
    };
    return apiRequest('/oauth/token', 'post', { ...secretKey, ...formData });
  },

  forgetPassword(userName: string) {
    return apiRequest('/api/employee/forgot-password', 'post', { username: userName })
  },

  getUserInfo(): Promise<UserProfileReponse> {
    return apiRequest('/api/employee/info', 'get');
  },

  logout() {
    return apiRequest('/api/employee/oauth/tokens/logout', 'post')
  },

  resetPassword(token: string) {
    return apiRequest(`/api/employee/reset-password/${token}`, 'get')
  },

  getSavedFilters(): Promise<ApiResponseList<FilterData[]>> {
    return apiRequest('/api/customer/filters');
  },

  getSavedLoanFilters(): Promise<ApiResponseList<LoanFilterData[]>> {
    return apiRequest('/api/loan/filters');
  },

  changePassword(data: object): Promise<ChangePasswordFetchResponse> {
    return apiRequest('api/employee/change-password', 'put', data)
  },

  updateProfile(data: object): Promise<EmployeeFetchResponse> {
    return apiRequest('api/employee/info', 'put', data)
  },

  getSavedTaskFilters(): Promise<ApiResponseList<TaskFilterData[]>> {
    return apiRequest('/api/task/filters');
  },
  turnOnMaintenanceSystem() {
    return apiRequest('api/system/down', 'post');
  },
  turnOffMaintenanceSystem(token: string) {
    return apiRequest(`api/system/up/${token}`, 'post');
  },
  freezeabeChatSystem() {
    return apiRequest('api/settings/chat/freeze', 'post');
  },
  unFreezeabeChatSystem() {
    return apiRequest('api/settings/chat/unfreeze', 'post');
  },
};
