import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, resolveDirective as _resolveDirective, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h6 text-center" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: _normalizeClass(_ctx.classDialog),
        style: _normalizeStyle({width: _ctx.width, 'max-width': '80vw'})
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.title]
          ]),
          _createVNode(_component_q_card_section, { class: "q-pt-none text-center" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "content", {}, () => [
                _createElementVNode("span", { innerHTML: _ctx.content }, null, 8, _hoisted_2)
              ])
            ]),
            _: 3
          }),
          _createVNode(_component_q_card_actions, { align: "center" }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_q_btn, {
                outline: "",
                label: _ctx.closeTxt,
                color: "primary"
              }, null, 8, ["label"]), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          })
        ]),
        _: 3
      }, 8, ["class", "style"])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}