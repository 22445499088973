
  import { defineComponent, computed, PropType } from 'vue';

  enum Type {sm = 'sm', md = 'md', full = 'full'}
  export default defineComponent({
    name: 'SuccessModal',
    props: {
      title: {
        type: String,
        default: ''
      },
      modelValue: {
        type: Boolean,
        default: false,
      },
      content: {
        type: String,
        default: 'text'
      },
      closeTxt: {
        type: String,
        default: 'OK'
      },
      classDialog: {
        type: String,
        default: ''
      },
      size: {
        type: String as PropType<Type>,
        default: 'sm'
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit}) {
      const width = computed(() => {
        if (props.size === 'full')
          return '100%';
        else if (props.size === 'md')
          return '700px';
        else
          return '500px'
      });

      const open = computed({
        get: () => props.modelValue,
        set: value => emit('update:modelValue', value)
      });

      return { open, width }
    },
  });
