import { defineStore } from 'pinia';
import {FilterData, LoanFilterData, TaskFilterData} from 'src/models/common';

export const useSavedFiltersStore = defineStore('savedFiltersStore', {
  state: () => ({
    hasFetchFilters: false,
    savedFilters: [] as FilterData[],
    visibleFilters: [] as FilterData[],
    hasFetchLoanFilters: false,
    savedLoanFilters: [] as LoanFilterData[],
    visibleLoanFilters: [] as LoanFilterData[],
    hasFetchTaskFilters: false,
    savedTaskFilters: [] as TaskFilterData[],
    visibleTaskFilters: [] as TaskFilterData[],
  }),
  getters: {
    //
  },
  actions: {
    setHasFetchFilters(check: boolean) {
      this.hasFetchFilters = check;
    },
    setSavedFilters(filters: FilterData[]) {
      this.savedFilters = filters;
    },
    setVisibleFilters(filters: FilterData[]) {
      this.visibleFilters = filters;
    },
    setHasFetchLoanFilters(check: boolean) {
      this.hasFetchLoanFilters = check;
    },
    setSavedLoanFilters(filters: LoanFilterData[]) {
      this.savedLoanFilters = filters;
    },
    setVisibleLoanFilters(filters: LoanFilterData[]) {
      this.visibleLoanFilters = filters;
    },
    setHasFetchTaskFilters(check: boolean) {
      this.hasFetchTaskFilters = check;
    },
    setSavedTaskFilters(filters: TaskFilterData[]) {
      this.savedTaskFilters = filters;
    },
    setVisibleTaskFilters(filters: TaskFilterData[]) {
      this.visibleTaskFilters = filters;
    },
    resetFilters() {
      this.hasFetchFilters = false
      this.hasFetchLoanFilters = false
      this.hasFetchTaskFilters = false
      this.savedFilters = [] as FilterData[]
      this.visibleFilters = [] as FilterData[]
      this.savedLoanFilters = [] as LoanFilterData[]
      this.visibleLoanFilters = [] as LoanFilterData[]
      this.savedTaskFilters = [] as TaskFilterData[]
      this.visibleTaskFilters = [] as TaskFilterData[]
    }
  }
});
