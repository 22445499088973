import { defineStore } from 'pinia';
import {ListEmployeeData} from 'src/models/employee';

export const useListEmployeesStore = defineStore('listEmployees', {
    state: () => ({
        employees: [] as ListEmployeeData[],
    }),
    getters: {
    },
    actions: {
        setState(employees: ListEmployeeData[]) {
            this.employees = employees;
        }
    }
});
