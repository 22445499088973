import {onMounted, computed} from 'vue';
import {storeToRefs} from 'pinia';
import {getListEmployee} from 'src/services/domains/employees/request';
import {ListEmployeeResponse} from 'src/models/api';
import {useListEmployeesStore} from 'src/stores/common/useListEmployeesStore';
import {ListEmployeeData} from 'src/models/employee';

export function useGetListUserOptions(showDepartment = false) {
  const listEmployeesStore = useListEmployeesStore();
  const {employees} = storeToRefs(listEmployeesStore);

  const mapUserOption = (user: ListEmployeeData) => {
    let departments = '';
    const name = user.name || 'No name';
    if (showDepartment && !!user.departments) {
      departments = user.departments.reduce((prev, department) => {
        return !!prev ? `${prev}, ${department.name}` : department.name
      },'');
    }
    return {
      value: user.id,
      label: !!departments ? `${name} (${departments})` : name
    }
  };
  const userOptions = computed(() => employees.value.map(user => mapUserOption(user)));

  const fetchAllEmployee = async () => {
    const listUsers: ListEmployeeResponse = await getListEmployee();
    listEmployeesStore.setState(listUsers.data.results);
  };

  onMounted(async () => {
    if (!employees.value.length) {
        await fetchAllEmployee();
    }
  });

  return {userOptions, fetchAllEmployee}
}
