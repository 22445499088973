import { axios } from 'src/boot/axios';
import {AxiosError, AxiosResponse, Method, ResponseType} from 'axios';
import {ApiResponse, Params} from 'src/models/api';
import {useRouter} from 'vue-router';
import { pushNotify } from 'src/helpers/notify';
import {useCancelRequestTokenStore} from 'src/stores/axios/useCancelRequestTokenStore';
import {useAuth} from 'src/services/domains/auth/useAuth';

export const apiRequest = (url: string, methodType: Method = 'get', requestData = {}, multipart = false, responseType: ResponseType = 'json', allowSkip = false) => {
  const isOnline = window.navigator.onLine;
  const router = useRouter();
  const {getAccessToken, removeAccessToken} = useAuth();
  const accessToken = getAccessToken();
  let params: Params = {};
  let data = {};
  const headers: {'Content-Type'?: string, Authorization?: string} = {
    'Content-Type': 'application/json'
  };
  const source = axios.CancelToken.source();
  if (allowSkip) {
    useCancelRequestTokenStore().addRequestToken(source);
  }

  if (multipart)
      headers['Content-Type'] = 'multipart/form-data';

    if (!!accessToken)
      headers['Authorization'] = `Bearer ${accessToken}`;

    if(!isOnline)
      pushNotify('danger', 'Lỗi ngoại tuyến.');

    if (methodType === 'get' || methodType === 'GET')
      params = requestData;
    else
      data = requestData

    return new Promise<ApiResponse>(
        (resolve, reject) => {
            axios({
                baseURL: process.env.URL_API,
                method: methodType,
                url: url,
                data,
                params,
                headers,
                responseType: responseType,
                cancelToken: source.token
            })
                .then(async function (response: AxiosResponse<ApiResponse>) {
                    if (response.data.code === 401 && response.data.error_messages === 'Unauthenticated.') {
                      removeAccessToken();
                      pushNotify('danger', 'Phiên đăng nhập hết hạn.');
                      await router.push({name: 'Login'});
                    }
                    else if (response.data.code === 500) {
                        pushNotify('danger', 'Lỗi máy chủ.');
                    }
                    else if (response.data.code === 503) {
                        if(!location.pathname.includes('maintenance') && !location.pathname.includes('system/up')) location.replace('maintenance');
                    }
                    else if (response.data.code >= 4000) {
                        pushNotify('danger', response.data.error_messages);
                    }
                    else if (response.data.code === 403) {
                      pushNotify('danger', 'Không có quyền thực hiện.');
                    }
                    resolve(response.data);
                })
                .catch(function (error: AxiosError) {
                  if (axios.isCancel(error)) {
                    console.log(error.message);
                  }
                  else {
                    reject(error.response);
                  }
                });
        });
};

export const buildParams = (params: Params) =>  {
    const arr: string[] = [];
    Object.keys(params).forEach(function(key: keyof Params) {
        arr.push(`${key}=${params[key]}`)
    });
    return '?' + arr.join('&');
};

export const buildFormData = (data: {[n: string]: string | number | [] | Blob | Blob[]}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)){
      value.forEach(item => {
        formData.append(`${key}[]`, item);
      });
    }
    else if (value instanceof Blob) {
      formData.append(key, value);
    }
    else {
      formData.append(key, !!value ? value.toString() : '');
    }

  }
  return formData;
};
