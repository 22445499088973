import { boot } from 'quasar/wrappers';
import Icons from 'src/components/common/AppIcons.vue'
import UserSelect from 'src/components/common/UserSelect.vue'
import DateInput from 'src/components/common/DateInput.vue'
import AppDataTable from 'src/components/common/AppDataTable.vue'
import SuccessModal from 'src/components/common/SuccessModal.vue'
import ConfirmModal from 'src/components/common/ConfirmModal.vue'
import ExportExcel from 'src/components/common/ExportExcel.vue'
import { DirectiveBinding } from 'vue';
import {can, canToAny, canTo} from 'src/services/utils/common';
import {permissions} from 'src/helpers/employee';

interface ClickOutsideInterface extends HTMLElement{
  clickOutsideEvent: EventListenerOrEventListenerObject
}

export default boot(({ app }) => {
  app.component('app-icon', Icons);
  app.component('user-select', UserSelect);
  app.component('date-input', DateInput);
  app.component('app-data-table', AppDataTable);
  app.component('modal-success', SuccessModal);
  app.component('modal-confirm', ConfirmModal);
  app.component('export-excel', ExportExcel);

  app.config.globalProperties.$canTo = canTo;
  app.config.globalProperties.$canToAny = canToAny;
  app.config.globalProperties.$can = can;
  app.config.globalProperties.$permissions = permissions;

  app.directive('click-outside', {
    beforeMount: (el: ClickOutsideInterface, binding: DirectiveBinding) => {
      el.clickOutsideEvent = (event) => {
        if (!(el == (event.target) || el.contains(event.target as Node|null))) {
          binding.value();
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted: (el: ClickOutsideInterface) => {
      document.removeEventListener('click', el.clickOutsideEvent);
    }
  });
})
