import { defineStore } from 'pinia';
import {CustomerDataMessage, CustomerMessage, Message} from 'src/models/message';

export const useChatStore = defineStore('chat', {
  state: () => ({
    countMessageUnSeen: 0,
    activeCustomer: {} as CustomerDataMessage,
    customers: [] as CustomerMessage[],
    firebaseMessage: {} as Message
  }),
  getters: {
    latestChatCustomers: (state) => {
      return state.customers.sort((x1, x2) => (x2.latest_message ? x2.latest_message.created_at : 0) - (x1.latest_message ? x1.latest_message.created_at : 0));
    }
  },
  actions: {
    setFirebaseMessage(message: Message) {
      this.firebaseMessage = message;
    },
    setActiveCustomer(customer: CustomerDataMessage) {
      this.activeCustomer = customer;
    },
    setCountMessageUnSeen(count: number) {
      this.countMessageUnSeen = count;
    },
    setCustomers(customers: CustomerMessage[]) {
      this.customers = customers;
    },
    addCustomer(customer: CustomerMessage) {
      this.customers.push(customer);
    },
    updateCustomer(customerId: number|string, data: object) {
      const customerIndex = this.customers.findIndex(item => Number(item.customer.id) === Number(customerId));
      if (customerIndex >= 0) {
        this.customers[customerIndex] = {...this.customers[customerIndex], ...data};
      }
    },
    updateOrCreateCustomer(customerId: number|string, data: CustomerMessage) {
      if (this.hasCustomer(customerId)) {
        this.updateCustomer(customerId, data);
      }
      else {
        this.addCustomer(data);
      }
    },
    hasCustomer(customerId: number|string) {
      const customerIndex = this.customers.findIndex(item => Number(item.customer.id) === Number(customerId));
      return customerIndex >= 0;
    },
    getCustomerData(customerId: number|string) {
      const customerIndex = this.customers.findIndex(item => Number(item.customer.id) === Number(customerId));
      return customerIndex >= 0 ? this.customers[customerIndex].customer : {};
    },
    resetCustomers() {
      this.customers = [];
      this.countMessageUnSeen = 0;
    }
  },
})
