import { Notify } from 'quasar'

export const pushNotify = (type: string, message: string, config?: object|null) => {
    let color;
    switch (type) {
        case 'success':
            color = 'positive';
            break;
        case 'warning':
            color = 'warning';
            break;
        case 'danger':
            color = 'negative';
            break;
        default:
            color = 'info';
            break;
    }
    Notify.create({
        message: message,
        badgeStyle: 'opacity: 0',
        color,
        ...config
    });
};

export const pushNewNotification = (title: string, message: string) => {
  Notify.create({
    type: 'info',
    icon: 'notifications',
    message: title,
    caption: message,
    color: 'white',
    textColor: 'black',
    position: 'bottom-right',
    classes: 'push-notify',
    iconSize: '35px',
    iconColor: 'white',
    timeout: 7000,
    badgeColor: 'red',
    badgeTextColor: 'white',
    badgeClass: 'shadow-3 glossy badge-notification-class'
  });
}
