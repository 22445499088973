import { defineStore } from 'pinia';
import {customerSelections} from 'src/helpers/customer';

export const useSelectionsStore = defineStore('selections', {
  state: () => ({
    hasFetchSelection: false,
    selections: {
      current_statuses: [],
      visa_types: [],
      potential_levels: [],
      source: [],
      loan_forms: [],
      loan_conditions: [],
      loan_statuses: [],
      currencies: [],
      japan_provinces: [],
      transferred_statuses: [],
      transaction_stages: [],
      transaction_types: [],
      task_types: [],
      task_status: [],
      task_priority_levels: [],
      contact_status: [],
      loan_income_statuses: [],
      loan_sources_of_income: [],
      genders: [],
      whitelist_emails: [],
      action_names: [],
      notification_schedule_types: [],
      bank_account_statuses: [],
      date_status: [],
    } as customerSelections,
  }),
  getters: {
    //
  },
  actions: {
    setSelections(state: customerSelections) {
      this.selections = state;
    },
    setHasFetchSelection(state = true) {
      this.hasFetchSelection = state;
    }
  }
});
