import {useAuthStore} from 'src/stores/auth/useAuthStore';
import {useChatStore} from 'src/stores/chat/useChatStore';
import {useSavedFiltersStore} from 'src/stores/personal/useSavedFiltersStore';
import {Login, LoginDataResponse} from 'src/models/auth';
import authService from 'src/services/domains/auth/request';
import {useNotRedNotificationStore} from 'src/stores/notification/useNotRedNotificationStore';
import {useCountWaitingTransactionStore} from 'src/stores/transaction/useCountWaitingTransactionStore';
import {useTaskStore} from 'src/stores/task/useTaskStore';
import {useSelectionsStore} from 'src/stores/common/useSelectionsStore';

export const useAuth = () => {
  const authStore = useAuthStore();
  const chatStore = useChatStore();
  const filterStore = useSavedFiltersStore();
  const notRedNotificationStore = useNotRedNotificationStore();
  const countWaitingTransactionStore = useCountWaitingTransactionStore();
  const taskStore = useTaskStore();
  const selectionsStore = useSelectionsStore();

  async function login(formData: Login) {
    try {
      const res = await authService.login(formData);
      if (res.code == 200) {
        const result = res.data as LoginDataResponse;
        localStorage.setItem('token_data', JSON.stringify(result));
        authStore.setIsAuthenticated();
      }
      return res;
    } catch (err) {
      //
    }
  }

  async function logout() {
    await authService.logout();
    resetStore()
    localStorage.removeItem('token_data');
  }

  async function authCheck() {
    if (!localStorage.getItem('token_data')) {
      authStore.setIsAuthenticated(false);
      return;
    }
    if (!Object.keys(authStore.userProfile).length) await getUserInfo();
    const accessToken = getAccessToken();
    authStore.setIsAuthenticated(!!accessToken);
  }

  function getUserInfo() {
    return authService.getUserInfo()
      .then(res => {
        if(res.code == 200 && res.data) {
          authStore.setUserProfile(res.data);
        }
      })
      .catch(()=> {
        //
      })
  }

  function getAccessToken(): string|null {
    const tokenData = JSON.parse(localStorage.getItem('token_data') as string) as LoginDataResponse;
    return !!tokenData ? tokenData.access_token : null;
  }

  function removeAccessToken() {
    localStorage.removeItem('token_data');
  }

  function resetStore() {
    taskStore.resetCountTask();
    authStore.resetUserProfile();
    chatStore.resetCustomers();
    filterStore.resetFilters();
    selectionsStore.setHasFetchSelection(false);
    countWaitingTransactionStore.resetTransactionNumber();
    notRedNotificationStore.resetNotReadNotification();
  }

  return {
    login,
    logout,
    authCheck,
    getUserInfo,
    getAccessToken,
    removeAccessToken
  }
}
