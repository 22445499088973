import {defineStore} from 'pinia';
import {CancelTokenSource} from 'axios';

export const useCancelRequestTokenStore = defineStore('cancelRequestTokenStore', {
  state: () => ({
    cancelRequestTokens: [] as CancelTokenSource[]
  }),
  getters: {
    //
  },
  actions: {
    addRequestToken(token: CancelTokenSource) {
      this.cancelRequestTokens.push(token);
    },
    resetRequestToken() {
      this.cancelRequestTokens = []
    },
    cancelRequests() {
      for (let i = 0; i < this.cancelRequestTokens.length; i++) {
        const request: CancelTokenSource = this.cancelRequestTokens[i];
        request.cancel('Request cancelled');
      }
      this.resetRequestToken();
    }
  }
});
